import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonFieldTypeEnum, SectionIntro } from '../../../openapi/atlantis';
import ErrorService from '../../../services/ErrorService.ts';
import { AnalyticsEventEnum } from '../../../types.ts';
import { ApplicationStepWithNewContent } from '../../../utils/ConfigDrivenFormUtils';
import Logger from '../../../utils/Logger';
import { removeHtmlTagsFromString } from '../../../utils/StringUtils.ts';
import AnalyticsEventOnLoad from '../../Analytics/AnalyticsEventOnLoad.tsx';
import ConfigDrivenContainerLayout from '../../Layout/ConfigDrivenContainerLayout';
import ControlledButtonInput from '../../inputs/ControlledButtonInput';
import ConfigDrivenSubtitle from '../Misc/ConfigDrivenSubtitle';
import ConfigDrivenTitle from '../Misc/ConfigDrivenTitle';

interface ConfigDrivenIntroContainerProps {
  config: ApplicationStepWithNewContent<SectionIntro>;
  onStart: (stepId: string) => void;
  onNext: () => void;
}

const ConfigDrivenIntroContainer = ({
  config,
  onStart,
  onNext,
}: ConfigDrivenIntroContainerProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm();

  const { id, title, subtitle, cta } = config.content || {};

  const handleOnSubmit = async () => {
    setIsSubmitting(true);
    try {
      await onStart(id!);
      await onNext();
    } catch (e: any) {
      Logger.error('Failed to submit answer:', e);
      ErrorService.notify('Failed to submit answer', e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ConfigDrivenContainerLayout config={config} hideMyTasks>
      <div className='flex flex-col justify-center mb-10'>
        {!!title && <ConfigDrivenTitle title={title} />}
        {!!subtitle && (
          <div className='pt-8'>
            <ConfigDrivenSubtitle subtitle={subtitle} />
          </div>
        )}
      </div>
      {cta?.type === ButtonFieldTypeEnum.Button && (
        <div className='flex flex-row self-start'>
          <ControlledButtonInput
            name={cta.id!}
            label={cta.label!}
            control={control}
            isDisabled={!isValid}
            isSubmitting={isSubmitting}
            onPress={handleSubmit(handleOnSubmit)}
            gradientVariant='mintyfresh'
          />
        </div>
      )}
      <AnalyticsEventOnLoad
        eventName={AnalyticsEventEnum.SECTION_INTRO_VIEWED}
        eventData={{
          section: removeHtmlTagsFromString(title!),
        }}
      />
    </ConfigDrivenContainerLayout>
  );
};

export default ConfigDrivenIntroContainer;
