import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  AnswerApplicationQuestionRequest,
  Question,
} from '../../../openapi/atlantis';
import { useAppSelector } from '../../../slices/store';
import {
  ApplicationStepWithNewContent,
  getFormDefaultValues,
  getPreviousQuestionId,
} from '../../../utils/ConfigDrivenFormUtils';
import Button from '../../Button';
import ConfigDrivenContainerLayout from '../../Layout/ConfigDrivenContainerLayout';
import ConfigDrivenForm from '../Form/ConfigDrivenForm';
import ConfigDrivenSubtitle from '../Misc/ConfigDrivenSubtitle';
import ConfigDrivenTitle from '../Misc/ConfigDrivenTitle';

interface ConfigDrivenQuestionContainerProps {
  config: ApplicationStepWithNewContent<Question>;
  onNext: () => void;
  onSubmit: (formData: AnswerApplicationQuestionRequest) => void;
  onNavigateToQuestion?: (questionId: string) => void;
}

const ConfigDrivenQuestionContainer: React.FC<
  ConfigDrivenQuestionContainerProps
> = ({ config, onNext, onSubmit, onNavigateToQuestion }) => {
  const { title, subtitle } = config?.content || {};
  const { progress } = useAppSelector((state) => state.application);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const prevQuestionId = getPreviousQuestionId(
    progress?.sectionProgresses || [],
    config.content.id,
  );

  const handleOnPrev = async (questionId: string) => {
    setIsLoading(true);
    await onNavigateToQuestion!(questionId);
    setIsLoading(false);
  };

  return (
    <ConfigDrivenContainerLayout
      config={config}
      onNavigateToQuestion={onNavigateToQuestion}
      hideMyTasks
    >
      <>
        {!!prevQuestionId && (
          <div className='flex flex-row justify-start mb-8'>
            <Button
              label='Previous'
              onPress={() => handleOnPrev(prevQuestionId)}
              variant='no-border'
              buttonSize={isMobile ? 'sm' : 'md'}
              isSubmitting={isLoading}
              leftIcon={
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  fontSize={16}
                  className='text-black'
                />
              }
            />
          </div>
        )}
        <div className='mb-10'>
          {!!title && <ConfigDrivenTitle title={title} />}
          {!!subtitle && (
            <div className='pt-8'>
              <ConfigDrivenSubtitle subtitle={subtitle} />
            </div>
          )}
        </div>
        <ConfigDrivenForm
          config={config}
          onNext={onNext}
          onSubmit={onSubmit}
          defaultValues={getFormDefaultValues(config)}
        />
      </>
    </ConfigDrivenContainerLayout>
  );
};

export default ConfigDrivenQuestionContainer;
